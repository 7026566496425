<template>
  <div>
    <v-card class="card-shadow border-radius-xl mt-6">
      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="services"
          :search="search"
          class="table"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat height="80">
              <v-row>
                <v-col cols="6" md="10" lg="8">
                  <v-text-field
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    :placeholder="'Search'|trans"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-search
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="2" lg="4" class="text-right">
                  <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                      font-weight-bold
                      text-capitalize
                      ms-auto
                      btn-default
                      bg-gradient-default
                      py-3
                      px-6
                      ms-3
                    "
                    @click="openServiceModal()"
                    ><span v-if="!$vuetify.breakpoint.mobile">{{ 'Add service'|trans }}</span>
                    <span v-else>{{ 'Add' | trans }}</span>
                  </v-btn>
                </v-col>
              </v-row>

              <services-form
                :show-modal="isServicesModalOpen"
                :service="selectedService"
                @closed="closeServiceModal"
                @created="getServicesList"
              ></services-form>
            </v-toolbar>
          </template>

          <template v-slot:item.name="{ item }">
            <span class="my-2 text-xs text-body font-weight-bold">
              <v-btn text @click="editService(item)">{{ item.name }}</v-btn>
            </span>
          </template>
          <template v-slot:item.description="{ item }">
            <span class="my-2 text-xs text-body font-weight-bold">{{
              item.description
            }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import ServicesForm from "@/views/Backoffice/Services/FormModal";

export default {
  name: "table-services",
  components: {
    ServicesForm,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      services: [],
      search: "",
      isServicesModalOpen: false,
      selectedService: {
        guid: null,
        name: "",
        description: "",
      },
    };
  },
  mounted() {
    this.getServicesList();
  },
  watch: {},
  methods: {
    openServiceModal() {
      this.isServicesModalOpen = true;
    },
    closeServiceModal() {
      this.isServicesModalOpen = false;
      this.selectedService = {
        guid: null,
        name: "",
        description: "",
      };
    },
    editService(service) {
      this.selectedService = this._.cloneDeep(service);
      this.openServiceModal();
    },
    getServicesList() {
      fetch(process.env.VUE_APP_API_URL + "/services/list", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": this.authToken,
          "Accept-Language": this.$store.getters.getLocale,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.type !== "success") {
            this.localAlert = {
              type: "error",
              color: "red",
              message: data.message ? data.message : this.$translator.trans("Unable to fetch list!"),
            };

            return false;
          }

          this.services = data.services;
        })
        .catch((error) => {
          console.error("Error:", error);

          this.localAlert = {
            type: "error",
            color: "red",
            message: error.message ? error.message : this.$translator.trans("Unable to fetch list!"),
          };
        });
    },
  },
  computed: {
    authToken() {
      return this.$store.getters.getAuthToken;
    },
    headers(){
      return [
        // {
        //   text: "ID",
        //   value: "guid",
        //   class: "text-secondary font-weight-bolder opacity-7",
        // },
        {
          text: this.$translator.trans("Name"),
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: this.$translator.trans("Description"),
          value: "description",
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ];
    }
  },
};
</script>
