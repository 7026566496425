<template>
  <div>
    <v-container fluid class="py-6">
      <v-row>
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
            <table-services></table-services>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import TableServices from "@/views/Backoffice/Services/Widgets/TableServices";

export default {
  name: "backoffice-services-list",
  components: {
    TableServices,
  },
  data() {
    return {};
  },
};
</script>
