<template>
  <v-dialog v-model="dialog" max-width="500px" @input="(v) => v || close()">
    <v-card class="card-shadow border-radius-xl">
      <div class="card-header-padding card-border-bottom">
        <span class="font-weight-bold text-h5 text-typo mb-0">{{ 'New service'|trans }}</span>
      </div>
      <v-form v-model="valid" ref="form">
        <v-card-text class="card-padding">
          <v-row v-if="localAlert">
            <v-col cols="12">
              <v-alert :type="localAlert.type" :color="localAlert.color">
                {{ localAlert.message }}
              </v-alert>
            </v-col>
          </v-row>

          <v-container class="px-0">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="service.name"
                  :rules="validation.required"
                  hide-details
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  :placeholder="'Name'|trans"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="service.description"
                  hide-details
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  :placeholder="'Description'|trans"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-alert
                    v-if="service.guid"
                    type="warning"
                    color="orange"
                    dense
                >
                  {{'When updating a service the text will be saved only for your current language. Other languages will not be updated automatically!'|trans}}
                </v-alert>
                <v-alert
                    v-else
                    type="warning"
                    color="orange"
                    dense
                >
                  {{'When creating a new service it will automatically be translated in all enabled languages.'|trans}}
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="close"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-secondary
              bg-gradient-light
              py-3
              px-6
            "
            >{{ 'Cancel'|trans }}
          </v-btn>

          <v-btn
            @click="save"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-gradient-default
              py-3
              px-6
            "
            >{{ 'Save'|trans }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "service-form",
  props: ["showModal", "service"],
  data() {
    return {
      valid: false,
      validation: {
        required: [(v) => !!v || "Is required"],
      },
      // service: {
      //   name:'',
      //   description:'',
      // },
      localAlert: null,
    };
  },
  methods: {
    close() {
      this.reset();
      this.$emit("closed");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    save() {
      this.localAlert = null;

      if (!this.service.name) {
        this.localAlert = {
          type: "error",
          color: "red",
          message: this.$translator.trans("Service name is required!"),
        };

        return false;
      }

      if (this.service.guid) {
        fetch(process.env.VUE_APP_API_URL + "/services/" + this.service.guid, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": this.authToken,
            "Accept-Language": this.$store.getters.getLocale,
          },
          body: JSON.stringify(this.service),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.type !== "success") {
              this.$notify({
                group: "global",
                type: "error",
                title: this.$translator.trans("Unable to update the service"),
                text: data.message ? data.message : "",
              });

              return false;
            }

            this.$notify({
              group: "global",
              type: "success",
              title: this.$translator.trans("Service update successfully!"),
              text: data.message ? data.message : "",
            });

            this.$emit("created");
          })
          .catch((error) => {
            console.error("Error:", error);

            this.$notify({
              group: "global",
              type: "error",
              title: this.$translator.trans("Unable to update the service"),
              text: error.message ? error.message : "",
            });
          });
      } else {
        fetch(process.env.VUE_APP_API_URL + "/services", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": this.authToken,
            "Accept-Language": this.$store.getters.getLocale,
          },
          body: JSON.stringify(this.service),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.type !== "success") {
              this.$notify({
                group: "global",
                type: "error",
                title: this.$translator.trans("Unable to create the service"),
                text: data.message ? data.message : "",
              });

              return false;
            }

            this.$notify({
              group: "global",
              type: "success",
              title: this.$translator.trans("Service created successfully!"),
              text: data.message ? data.message : "",
            });

            this.$emit("created");
          })
          .catch((error) => {
            console.error("Error:", error);

            this.$notify({
              group: "global",
              type: "error",
              title: this.$translator.trans("Unable to create the service"),
              text: error.message ? error.message : "",
            });
          });
      }

      this.close();
    },
  },
  watch: {},

  computed: {
    authToken() {
      return this.$store.getters.getAuthToken;
    },
    dialog: {
      get: function () {
        return this.showModal;
      },
      // setter
      set: function () {
        // if(!val){
        //   this.onClose();
        // }
      },
    },
  },
};
</script>
